/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useRef, useEffect, useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Video from "yet-another-react-lightbox/plugins/video";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import img1 from "../assets/images/ourProjects/2.png";
import img2 from "../assets/images/ourProjects/3.png";
import img3 from "../assets/images/ourProjects/4.png";
import img4 from "../assets/images/ourProjects/5.png";
import img5 from "../assets/images/ourProjects/6.png";
import img6 from "../assets/images/ourProjects/7.png";
import project1 from "../assets/images/ourProjects/new-project1.jpg";
import project2 from "../assets/images/ourProjects/new-project2.jpg";
import project3 from "../assets/images/ourProjects/new-project3.jpg";
import project4 from "../assets/images/ourProjects/new-project4.jpg";
import project5 from "../assets/images/ourProjects/new-project5.jpg";
import project6 from "../assets/images/ourProjects/new-project6.jpg";
import project8 from "../assets/images/ourProjects/new-project8.jpg";
import project9 from "../assets/images/ourProjects/new-project9.jpg";
import project10 from "../assets/images/ourProjects/new-project10.jpg";
import project11 from "../assets/images/ourProjects/new-project11.jpg";
import project12 from "../assets/images/ourProjects/new-project12.jpg";
import project13 from "../assets/images/ourProjects/new-project13.jpg";
import { LanguageContext } from "../context/LanguageContext";
import { ProjectSulaymanyiah, projectOthers } from "../data/data";
const Projects = () => {
  const projectImages = [
    {
      id: 1,
      src: project1,
    },
    {
      id: 2,
      src: project2,
    },
    {
      id: 3,
      src: project3,
    },
    {
      id: 4,
      src: project4,
    },
    {
      id: 5,
      src: project5,
    },
    {
      id: 6,
      src: project6,
    },
    {
      id: 7,
      src: project8,
    },
    {
      id: 8,
      src: project9,
    },
    {
      id: 9,
      src: project10,
    },
    {
      id: 10,
      src: project11,
    },
    {
      id: 11,
      src: project12,
    },
    {
      id: 12,
      src: project13,
    },
  ];
  const { texts, language } = useContext(LanguageContext);
  const imageRef = useRef(null);
  const currentIndexRef = useRef(0);
  const [isOpen, setIsopen] = useState(false);
  const images = [img1, img2, img3, img4, img5, img6];
  const [index, setIndex] = useState(-1);
  useEffect(() => {
    const interval = setInterval(() => {
      nextImage();
    }, 2500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const nextImage = () => {
    currentIndexRef.current = (currentIndexRef.current + 1) % images.length;
    imageRef.current.src = images[currentIndexRef.current];
  };

  return (
    <div>
      <div className="relative flex justify-center items-center mt-24">
        <img
          src={images[0]}
          alt="slideshow"
          ref={imageRef}
          className="lg:rounded-xl brightness-50 h-[500px] w-full lg:w-[60%] object-cover "
        />
        <h1 className="text-white text-2xl md:text-4xl absolute mt-[60%] md:mt-[15%]">
          {texts.our_projects}
        </h1>
        <div className={`flex  text-white  absolute  top-0 left-0 w-full   `}>
          <div
            className={`${
              language === "en"
                ? "flex justify-start ml-3 my-3 md:ml-10 lg:ml-[21%]"
                : "flex justify-end mr-3 my-3 md:mr-10 lg:mr-[21%] "
            }`}
          >
            <a href="/" className="">
              {texts.home}
            </a>
            &nbsp; {">"} &nbsp; <a className="">{texts.projects}</a>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap justify-center items-center px-12 xl:mx-96 gap-10 pt-5">
        {projectImages.map((item, index) => (
          <div className="flex relative justify-center items-center">
            <img
              src={item.src}
              alt="projects"
              className="object-cover w-[400px] rounded-md"
            />
            <div
              onClick={() => {
                setIndex(index);
                setIsopen(true);
              }}
              className="absolute bottom-0 left-0 right-0 top-0 h-full cursor-pointer bg-five bg-opacity-75 flex items-end justify-center text-white p-2 opacity-0 hover:opacity-100 transition-opacity duration-300 rounded-lg"
            >
              <h1 className="text-white font-bold text-xl text-center my-auto">
                {item.id}
              </h1>
            </div>
          </div>
        ))}
      </div>
      <Lightbox
        index={index}
        open={isOpen}
        close={() => setIsopen(false)}
        slides={projectImages}
        plugins={[Fullscreen, Slideshow, Thumbnails, Video, Zoom]}
      />
      <p className="text-black text-md font-bold text-center mx-4 mt-10 md:mt-[2%] ">
        {texts.our_projects_text}
      </p>
      <div className=" flex flex-col justify-start items-start text-start my-10 mx-10 md:mx-16 lg:mx-[28%] gap-5">
        <h1 className="font-bold text-xl pb-3">{texts.sulaymanyiah}</h1>
        {ProjectSulaymanyiah.map((item) => (
          <div className="flex flex-col justify-center items-center ">
            <ul className="list-disc" dir="">
              <li className="" dir="">
                {language === "en" ? item.titleENG : item.titleAR}
              </li>
            </ul>
          </div>
        ))}
      </div>
      <div className="flex flex-col justify-start items-start text-start my-10 mx-10 md:mx-16 lg:mx-[28%] gap-5">
        <h1 className="font-bold text-xl py-6">{texts.others}</h1>
        {projectOthers.map((item) => (
          <div className="flex flex-col justify-center items-center ">
            <ul className="list-disc" dir="">
              <li className="" dir="">
                {language === "en" ? item.titleENG : item.titleAR}
              </li>
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Projects;
