/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */

import ServiceSection from "./serviceSection";
import ProjectSection from "./projectSection";
import Aboutsection from "./aboutsection";
import Hero from "./Hero";
import Certificates from "./Certificates";
const Index = () => {
  return (
    <div className="" id="home">
      <Hero />
      <Aboutsection />
      <ServiceSection />
      <ProjectSection />
      <Certificates />
    </div>
  );
};

export default Index;
