/* eslint-disable react/jsx-no-target-blank */
import React, { useContext } from "react";
import { LanguageContext } from "../context/LanguageContext";
import { footerIcon } from "../data/data";

const Footer = () => {
  const { texts, language } = useContext(LanguageContext);
  return (
    <div
      className="flex flex-col justify-center items-center bg-fourth w-full h-full mt-10 "
      id="contact"
    >
      <div className="flex justify-center items-center  mx-5  lg:w-[50%] my-10 tracking-wide ">
        <p className="text-xl text-third text-center">{texts.footer_text}</p>
      </div>

      <div className="flex flex-wrap justify-center items-center gap-6">
        {footerIcon.map((item) => (
          <div className="flex justify-center items-center mx-5 ">
            <img src={item.mainICON} alt={item.id} />
            <h1 className="lg:text-xl  px-3 ">
              {language === "en" ? item.titleEng : item.titleAr}
            </h1>
          </div>
        ))}
      </div>

      <div className="flex justify-center items-center text-center mt-4 border border-t-2 border-t-primary w-[70%] ">
        <p className="text-md text-third my-5" dir="ltr">
          ©{new Date().getFullYear()}{" "}
          <a
            target="_blank"
            href="https://www.khaledshinky.com/"
            className="hover:underline"
          >
            Shinke Company
          </a>
          . All Rights Reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
