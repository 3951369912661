/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState } from "react";
import { LanguageContext } from "../../context/LanguageContext";
import "aos/dist/aos.css";
import {
  ChevronDoubleDownIcon,
  ChevronDoubleUpIcon,
} from "@heroicons/react/24/solid";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Video from "yet-another-react-lightbox/plugins/video";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import certificate1 from "../../assets/images/certificates/certificate1.png";
import certificate2 from "../../assets/images/certificates/certificate2.png";
import certificate3 from "../../assets/images/certificates/certificate3.png";
import certificate4 from "../../assets/images/certificates/certificate4.png";
import certificate5 from "../../assets/images/certificates/certificate5.png";
import certificate6 from "../../assets/images/certificates/certificate6.png";

import certificate7 from "../../assets/images/certificates/certificate7.png";
import certificate8 from "../../assets/images/certificates/certificate8.png";
import certificate9 from "../../assets/images/certificates/certificate9.png";
import certificate10 from "../../assets/images/certificates/certificate10.png";
import certificate11 from "../../assets/images/certificates/certificate11.png";
import certificate12 from "../../assets/images/certificates/certificate12.png";
import certificate13 from "../../assets/images/certificates/certificate13.png";
import certificate14 from "../../assets/images/certificates/certificate14.png";
const Certificates = () => {
  const Certificates = [
    {
      id: 0,
      src: certificate1,
    },
    {
      id: 1,
      src: certificate2,
    },
    {
      id: 2,
      src: certificate3,
    },
    {
      id: 3,
      src: certificate4,
    },
    {
      id: 4,
      src: certificate5,
    },
    {
      id: 5,
      src: certificate6,
    },
    {
      id: 6,
      src: certificate7,
    },
    {
      id: 7,
      src: certificate8,
    },
    {
      id: 8,
      src: certificate9,
    },
    {
      id: 9,
      src: certificate10,
    },
    {
      id: 10,
      src: certificate11,
    },
    {
      id: 11,
      src: certificate12,
    },
    {
      id: 12,
      src: certificate13,
    },
    {
      id: 13,
      src: certificate14,
    },
  ];
  const { texts } = useContext(LanguageContext);
  const [isOpen, setIsopen] = useState(false);
  const [isArrow, setIsArrow] = useState(false);
  const [index, setIndex] = useState(-1);

  const [visibleCertificates, setVisibleCertificates] = useState(
    Certificates.slice(0, 6)
  );
  const [remainingCertificates, setRemainingCertificates] = useState(
    Certificates.slice(6)
  );
  const handleArrowClick = () => {
    setIsArrow((prev) => !prev);
    if (!isArrow) {
      setVisibleCertificates(Certificates);
      setRemainingCertificates([]);
    } else {
      setVisibleCertificates(Certificates.slice(0, 6));
      setRemainingCertificates(Certificates.slice(6));
    }
  };

  return (
    <div id="certificates">
      <h1
        className="flex justify-center items-center text-3xl  md:text-3xl font-bold mt-16"
        data-aos="fade-up"
      >
        <span className="border-b-2 border-primary p-3">
          {texts.certificates}
        </span>
      </h1>
      <div className="flex justify-center items-center ">
        <p
          className="text-third text-md md:text-xl mt-8 mb-20 flex  text-center"
          data-aos="fade-up"
        >
          {texts.certificates_text}
        </p>
      </div>

      <div
        className="flex flex-wrap justify-center items-center px-12 xl:mx-96  gap-10"
        data-aos="zoom-in"
      >
        {visibleCertificates.map((item, index) => (
          <div
            key={index}
            className="flex relative justify-center items-center "
          >
            <img
              src={item.src}
              alt={item.id}
              className="object-cover w-[400px] rounded-xl"
            />
            <div
              className="absolute bottom-0 left-0 right-0 top-0 h-full cursor-pointer bg-five bg-opacity-75 flex items-end justify-center text-white p-2 opacity-0 hover:opacity-100 transition-opacity duration-300 rounded-lg"
              onClick={() => {
                setIndex(index);
                setIsopen(true, item.src);
              }}
            >
              <h1 className="text-white font-bold text-xl text-center my-auto">
                {item.id + 1}
              </h1>
            </div>
          </div>
        ))}
      </div>
      <div
        className={`relative flex justify-center items-center lg:mx- pt-14 pb-5`}
      >
        <button className="" onClick={handleArrowClick}>
          {!isArrow ? (
            <ChevronDoubleDownIcon className="h-8 w-8 " />
          ) : (
            <ChevronDoubleUpIcon className="h-8 w-8 " />
          )}
        </button>
        <div className="absolute top-20">
          {isArrow ? (
            <div
              className="flex flex-wrap justify-center items-center px-12 xl:mx-96  gap-10"
              data-aos="zoom-in"
            >
              {remainingCertificates.map((item, index) => (
                <div
                  key={index}
                  className="flex relative justify-center items-center "
                >
                  <img
                    src={item.src}
                    alt={item.id}
                    className="object-cover w-[400px] rounded-xl"
                  />
                  <div
                    className="absolute bottom-0 left-0 right-0 top-0 h-full bg-five bg-opacity-75 flex items-end 
                                justify-center text-white p-2 opacity-0 hover:opacity-100 transition-opacity duration-300 rounded-lg"
                    onClick={() => {
                      setIndex(index);
                      setIsopen(true, item.src);
                    }}
                  >
                    <h1 className="text-white font-bold text-xl text-center my-auto">
                      {item.id + 1}
                    </h1>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            ""
          )}
        </div>
        <Lightbox
          index={index}
          open={isOpen}
          close={() => setIsopen(false)}
          slides={Certificates}
          plugins={[Fullscreen, Slideshow, Thumbnails, Video, Zoom]}
        />
      </div>
    </div>
  );
};

export default Certificates;
