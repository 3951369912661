import React, { useState, useEffect } from "react";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { useContext } from "react";
import { LanguageContext } from "../context/LanguageContext";
import { useLocation, useNavigate, Link } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;

  const [nav, setNav] = useState(false);
  const [scrollMove, setScrollMove] = useState(false);
  const [color, setColor] = useState("transparent");
  const [textColor, setTextColor] = useState("white");

  const handleNav = () => {
    setNav(!nav);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const yOffset = -190;
      const y =
        section.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
      handleNav();
    }
  };

  useEffect(() => {
    if (path === "/") {
      setTextColor("white");
    } else {
      setTextColor("black");
    }
    const changeColor = () => {
      if (window.scrollY >= 90) {
        setColor("rgba(0, 0, 0, 0.5) ");

        setTextColor("white");
      } else {
        setColor("transparent");
        if (path != "/") {
          setTextColor("black");
        } else {
          setTextColor("white");
        }
      }
    };

    const handleScroll = () => {
      changeColor();
      setScrollMove(window.scrollY >= 90);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const { texts, language, changeLanguage } = useContext(LanguageContext);

  const toggleLanguage = (lang) => {
    changeLanguage(lang);
  };

  return (
    <nav
      style={{ backgroundColor: `${color}` }}
      className="fixed left-0 top-0 w-full z-40 ease-in duration-300   "
    >
      <div className="max-w-[1300px] m-auto flex justify-between items-center p-4 text-white ">
        <div className="flex items-center ">
          <a href="/" dir="ltr">
            {path !== "/" ? (
              <h1
                style={{ color: `${textColor}` }}
                className={`text-black text-xl tracking-wider ${
                  language === "en" ? "mr-10" : "ml-10"
                }`}
              >
                SHINKE.
              </h1>
            ) : (
              <h1
                className={`text-white text-xl tracking-wider ${
                  language === "en" ? "mr-10" : "ml-10"
                }`}
              >
                SHINKE.
              </h1>
            )}
          </a>

          <ul
            style={{ color: `${textColor}` }}
            className="hidden sm:flex items-center"
          >
            <li className="p-1 md:p-1 lg:p-4 text-lg  hover:text-primary">
              {path !== "/" ? (
                <a href="/about">{texts.about}</a>
              ) : (
                <Link onClick={() => scrollToSection("about")}>
                  {texts.about}
                </Link>
              )}
            </li>
            <li className="p-1 md:p-2 lg:p-4 text-lg  hover:text-primary">
              {path != "/" ? (
                <a href="/">{texts.services}</a>
              ) : (
                <Link onClick={() => scrollToSection("services")}>
                  {texts.services}
                </Link>
              )}
            </li>
            <li className="p-1 md:p-1 lg:p-4 text-lg  hover:text-primary">
              {path != "/" ? (
                <a href="/projects">{texts.projects}</a>
              ) : (
                <Link onClick={() => scrollToSection("projects")}>
                  {texts.projects}
                </Link>
              )}
            </li>
            <li className="p-1 md:p-2 lg:p-4 text-lg  hover:text-primary">
              {path != "/" ? (
                <a href="/">{texts.certificates}</a>
              ) : (
                <Link onClick={() => scrollToSection("certificates")}>
                  {texts.certificates}
                </Link>
              )}
            </li>

            <li className="p-1 md:p-1 lg:p-4 text-lg  hover:text-primary">
              <Link onClick={() => scrollToSection("contact")}>
                {texts.contact}
              </Link>
            </li>
          </ul>
        </div>
        <div
          className={`hidden sm:flex ${language === "en" ? "ml-2" : "mr-2"}`}
        >
          <button
            className={`border border-primary  font-bold w-14 h-8 ${
              language === "en"
                ? "bg-primary rounded-l-md text-white"
                : `rounded-r-md text-${textColor}`
            } `}
            onClick={() => toggleLanguage("en")}
          >
            EN
          </button>

          <button
            className={`border border-primary  font-bold w-14 h-8 ${
              language === "ar"
                ? "bg-primary rounded-l-md text-white"
                : `rounded-r-md text-${textColor}`
            }`}
            onClick={() => toggleLanguage("ar")}
          >
            AR
          </button>
        </div>

        {/* Mobile Button */}
        <div className="flex sm:hidden z-10 items-center justify-center">
          {/* Language buttons */}
          <button
            className={`border border-primary  font-bold w-10 h-8 textColor ${
              language === "en"
                ? "bg-primary rounded-l-md text-white"
                : `rounded-r-md ${
                    nav
                      ? `text-black rounded-sm bg-secondary`
                      : `text-${textColor}`
                  }`
            }`}
            onClick={() => toggleLanguage("en")}
          >
            EN
          </button>

          <button
            className={`border border-primary font-bold w-10 h-8  ${
              language === "ar"
                ? "bg-primary rounded-l-md text-white"
                : ` text-black rounded-r-md  ${
                    nav
                      ? `text-black rounded-sm bg-secondary`
                      : `text-${textColor}`
                  } `
            }`}
            onClick={() => toggleLanguage("ar")}
          >
            AR
          </button>

          {/* Mobile navigation button */}
          <button
            onClick={handleNav}
            className={`${language === "en" ? "ml-4" : "mr-4"}`}
          >
            {nav ? (
              <AiOutlineClose size={32} style={{ color: `${textColor}` }} />
            ) : (
              <AiOutlineMenu size={32} style={{ color: `${textColor}` }} />
            )}
          </button>
        </div>

        {/* Mobile Menu */}
        <div
          className={` bg-black/70 flex flex-col text-center justify-center items-center sm:hidden transition-all duration-300 fixed inset-x-0 top-0 overflow-hidden ${
            nav ? "block h-[100vh]" : "h-0"
          }`}
        >
          <ul>
            <li className="p-4 text-lg font-bold hover:text-primary">
              {path != "/" ? (
                <a href="/about">{texts.about}</a>
              ) : (
                <Link onClick={() => scrollToSection("about")}>
                  {texts.about}
                </Link>
              )}
            </li>
            <li className="p-4 text-lg font-bold hover:text-primary">
              {path != "/" ? (
                <a href="/">{texts.services}</a>
              ) : (
                <Link onClick={() => scrollToSection("services")}>
                  {texts.services}
                </Link>
              )}
            </li>
            <li className="p-4 text-lg font-bold hover:text-primary">
              {path != "/" ? (
                <a href="/projects">{texts.projects}</a>
              ) : (
                <Link onClick={() => scrollToSection("projects")}>
                  {texts.projects}
                </Link>
              )}
            </li>
            <li className="p-4 text-lg font-bold hover:text-primary">
              {path != "/" ? (
                <a href="/">{texts.certificates}</a>
              ) : (
                <Link onClick={() => scrollToSection("certificates")}>
                  {texts.certificates}
                </Link>
              )}
            </li>

            <li className="p-4 text-lg font-bold hover:text-primary">
              <Link onClick={() => scrollToSection("contact")}>
                {texts.contact}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
