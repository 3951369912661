import React, { useContext } from "react";
import { LanguageContext } from "../../context/LanguageContext";
import ServicesIcon from "../../components/servicesIcon";
import washing from "../../assets/images/washing Icon.png";
import cleaning from "../../assets/images/cleaning Icon.png";
import waste_disposal from "../../assets/images/waste disposal icon.png";

const ServiceSection = () => {
  const { texts, language } = useContext(LanguageContext);

  return (
    <div className="mx-5 md:mx-5 first-letter:lg:mx-64" id="services">
      <h1 className="flex justify-center items-center text-2xl  md:text-3xl font-bold mt-16  ">
        <span className="border-b-2 border-primary p-3" data-aos="fade-right">
          {texts.services}
        </span>
      </h1>
      <div className="flex justify-center items-center ">
        <p
          className="text-third text-sm md:text-xl mt-8 mb-5 flex  text-center"
          data-aos="fade-left"
        >
          {texts.service_text}
        </p>
      </div>

      <div
        className={`flex flex-wrap  justify-center items-center   ${
          language !== "en" ? "font-arkufi" : "font-roboto"
        }`}
      >
        <ServicesIcon
          title={texts.cleaning}
          icon={cleaning}
          subtitle={texts.cleaning_subtitle}
        />
        <ServicesIcon
          title={texts.waste_disposal}
          icon={waste_disposal}
          subtitle={texts.waste_disposal_subtitle}
        />
        <ServicesIcon
          title={texts.washing}
          icon={washing}
          subtitle={texts.washing_subtitle}
        />
      </div>
    </div>
  );
};

export default ServiceSection;
