/* eslint-disable eqeqeq */
import React, { useContext } from "react";
import { LanguageContext } from "../../context/LanguageContext";
import aboutsecimg from "../../assets/images/aboutSecImg.png";
import Aos from "aos";
import "aos/dist/aos.css";
const Aboutsection = () => {
  const { texts, language } = useContext(LanguageContext);
  return (
    <div className="h-fullw-full  " id="about">
      <h1
        className="flex justify-center items-center text-2xl  md:text-3xl font-bold mt-10"
        data-aos="fade-up"
      >
        <span className="border-b-2 border-primary p-3">{texts.about}</span>
      </h1>
      <div
        className="lg:relative flex justify-center mt-10 md:mt-36 mx-5 items-center  "
        data-aos="zoom-in"
      >
        <div className=" md:grid   md:grid-cols-2">
          <div className="  grid-cols-1 text-start flex flex-col justify-center">
            <p
              className={` ${
                language != "en"
                  ? "lg:20 2xl:mr-72 text-center md:text-start  md:text-xl text-third "
                  : "text-center md:text-start  md:text-xl text-third lg:ml-20 2xl:ml-72"
              }`}
            >
              {texts.about_text}
            </p>
            <a
              href="/about"
              className={`${
                language != "en"
                  ? "mx-auto  w-60 h-8 md:mx-0 mb-10 lg:20 2xl:mr-72 border-2 border-primary bg-primary md:w-64 md:h-10 rounded-md p-2 mt-8 text-xl font-bold flex justify-center items-center hover:bg-transparent hover:text-primary hover:border-2 hover:border-primary   transition-all duration-150 text-secondary"
                  : "read-btn"
              }`}
            >
              {texts.read_more}
            </a>
          </div>

          <div className=" flex justify-center items-center ">
            <img src={aboutsecimg} alt="" className="md:ml-0 " />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Aboutsection;
