import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../pages/home/index";
import About from "../pages/About";
import Projects from "../pages/Projects";

import HomeButton from "../components/Homebtn";
const PageRoutes = () => {
  return (
    <>
      <Routes>
        <Route
          path="/"
          index
          element={
            <div className="w-full">
              <Home />
              <HomeButton />
            </div>
          }
        />
        <Route
          path="/about"
          element={
            <div className="w-full">
              <About />
            </div>
          }
        />
        <Route
          path="/projects"
          element={
            <div className="w-full">
              <Projects />
            </div>
          }
        />
        
      </Routes>
    </>
  );
};

export default PageRoutes;
