import Locatation from "../assets/images/footer icon/location.png";
import Facebook from "../assets/images/footer icon/facebook.png";
import Email from "../assets/images/footer icon/email.png";
import Phone from "../assets/images/footer icon/phone number.png";


export const footerIcon = [
  {
    id: 0,
    mainICON: Locatation,
    titleEng: "Qrga- opposite to Shari Spi",
    titleAr: "قرغا- مقابل شاري سبي",
  },
  {
    id: 1,
    mainICON: Email,
    titleEng: "shinkecompany@gmail.com",
    titleAr: "shinkecompany@gmail.com",
  },
  {
    id: 2,
    mainICON: Facebook,
    titleEng: "Shinke Company",
    titleAr: "Shinke Company",
  },
  {
    id: 3,
    mainICON: Phone,
    titleEng: "+9647701529370",
    titleAr: "9647701529370+",
  },
];
export const ProjectSulaymanyiah = [
  {
    id: 0,
    titleENG: "cleaning and transforming Rubbish  South of sulaimanyah",
    titleAR: "تنظيف وتحويل النفايات جنوب السليمانية",
  },
  {
    id: 1,
    titleENG: "cleaning and transforming Rubbish  western of sulaimanyah",
    titleAR: "تنظيف وتحويل النفايات غربي السليمانية",
  },]

  export const projectOthers = [{

    id: 0,
    titleENG: " Khurmall   town Drainage ",
    titleAR: "صرف بلدة خرمال",
    title : "others",
  },
  {
    id: 1,
    titleENG: "Qrga 30 meters  Road",
    titleAR: "طريق قرجة 30 متر",
  },
  {
    id: 2,
    titleENG: "Weight  Center in Chwarqurna",
    titleAR: "مركز الوزن في خوارقرنة",
  },
  {
    id: 3,
    titleENG: "Ibrahim ahmad Quarter (service project)",
    titleAR: "حي ابراهيم احمد (مشروع خدمي)        ",
  },
  {
    id: 4,
    titleENG: " Sarwchawa dam project ",
    titleAR: "مشروع سد صروشا        ",
  },
  {
    id: 5,
    titleENG: "Danalle – Mawat road project ",
    titleAR: "مشروع طريق دانال - موات        ",
  },
  {
    id: 6,
    titleENG: "Merade – Ahmad Brna road project",
    titleAR: "مشروع طريق ميراد - احمد برنا        ",
  },
  {
    id: 7,
    titleENG: " Dara Baru – SarChnar road project",
    titleAR: "مشروع طريق دارا بارو - سارشنار        ",
  },
  {
    id: 8,
    titleENG: "Saray subhan Agha bridge project ",
    titleAR: "مشروع جسر سراي سبحان آغا        ",
  },
  {
    id: 9,
    titleENG: " Changh Chyan bridge project",
    titleAR: "مشروع جسر تشانغ تشيان                ",
  },
  {
    id: 10,
    titleENG: "Sayd sadq Drainage box project ",
    titleAR: "مشروع صندوق صرف سيد صادق        ",
  },
  {
    id: 11,
    titleENG: "Shekh Qadery Chwes Drainage  project",
    titleAR: "مشروع صرف الشيخ قادري شويس        ",
  },
  {
    id: 12,
    titleENG: " Kani speak Drainage",
    titleAR: "كاني يتكلم الصرف        ",
  },
  {
    id: 13,
    titleENG: "Tainal water supply project",
    titleAR: "مشروع توصيل مياه تينال        ",
  },
  {
    id: 14,
    titleENG: " Azmar bridge – Gaplwen- Shekh muhamad Kani tu / Part one ",
    titleAR: "جسر العزمار - جابلوين - الشيخ محمد كاني تو / الجزء الأول        ",
  },
  {
    id: 15,
    titleENG: "Azmar bridge – Gaplwen- Shekh muhamad Kani tu / Part two",
    titleAR:
      "جسر العزمار - جابلوين - الشيخ محمد كاني تو / الجزء الثاني        ",
  },
  {
    id: 16,
    titleENG: "Sulaimanyah airport  Road mixture ",
    titleAR: "خليط طريق مطار السليمانية        ",
  },
  {
    id: 17,
    titleENG: "Ranya Community",
    titleAR: "مجتمع رانيا        ",
  },

  {
    id: 18,
    titleENG: "Djel road project and service ",
    titleAR: "مشروع طريق جل وخدمته        ",
  },
  {
    id: 19,
    titleENG: "Balad main road and service project",
    titleAR: "مشروع طريق البلد الرئيسي والخدمي        ",
  },
  {
    id: 20,
    titleENG: "Hasan askar samara Shrine  project ",
    titleAR: "مشروع مرقد حسن عسكر سمارة        ",
  },
  {
    id: 21,
    titleENG: "Built Eunat mosqut in tkret",
    titleAR: "بني عونات مسقوت في تكريت        ",
  },
  {
    id: 22,
    titleENG: "Build wood Store / part one",
    titleAR: "بناء مخزن أخشاب / الجزء الأول        ",
  },
  {
    id: 23,
    titleENG: "Build  business store in tkret ",
    titleAR: "بناء محل تجاري في تكريت        ",
  },
  {
    id: 24,
    titleENG: "Build road and Drainage for Tkret store ",
    titleAR: "إنشاء طريق وصرف لمخزن تكريت        ",
  },
  {
    id: 25,
    titleENG: " road and Drainage project in Hara quarter (tkret)",
    titleAR: "مشروع الطرق والصرف بحي حارة (تكريت)        ",
  },
  {
    id: 26,
    titleENG: "Road and service project in hay shshin (tkret)",
    titleAR: "مشروع طريق وخدمي بحي شن (تكريت)        ",
  },
  {
    id: 27,
    titleENG: "Service project in beje – tkret main road",
    titleAR: "مشروع خدمي في طريق بيج - تكريت العام        ",
  },
  {
    id: 28,
    titleENG: "Beje – tkreet way (oil station)",
    titleAR: "بيجي - طريق تكريت (محطة بترول)        ",
  },
  {
    id: 29,
    titleENG: "Dur center road project",
    titleAR: "مشروع طريق مركز دور        ",
  },
  {
    id: 30,
    titleENG: "Chamchamal agriculture ( garage and building project)",
    titleAR: "جمجمال الزراعية (مشروع الجراج والبناء)        ",
  },
  {
    id: 31,
    titleENG: "Provide rock and dusty from bazian to Kirkuk Cement factory",
    titleAR: "توفير الصخور والغبار من بازيان الى معمل اسمنت كركوك        ",
  },
  {
    id: 32,
    titleENG: "Providing white rock to Kirkuk Cement factory",
    titleAR: "تجهيز مصنع اسمنت كركوك بالصخر الابيض        ",
  },
  {
    id: 33,
    titleENG: "Build qaladze and pshdar Conversation community ",
    titleAR: "بناء مجتمع محادثة قلادزة وبشدار        ",
  },
  {
    id: 34,
    titleENG: "Build sulaimayah 14th class school",
    titleAR: "بناء مدرسة السليمانية للصف الرابع عشر        ",
  },
  {
    id: 35,
    titleENG: "Provide Gravel  sand +tube+ warm needs for dukan tube line ",
    titleAR:
      "توفير رمل الحصى + أنبوب + احتياجات تدفئة لخط أنابيب دوكان        ",
  },
  {
    id: 36,
    titleENG:
      "Cleaning and transforming   rubbish from (Municipal ,1,2,3,4,5,6)",
    titleAR:
      ".تنظيف وتحويل النفايات من (البلدية ، 1 ، 2 ، 3 ، 4 ، 5 ، 6)        ",
  },
  {
    id: 37,
    titleENG:
      "Cleaning and transforming   rubbish ( all hospitals in Kirkuk , haweja , tuz khurmatur)",
    titleAR:
      ".تنظيف وتحويل النفايات (جميع المستشفيات في كركوك ، الحويجة ، طوز خورماتور)        ",
  },
  {
    id: 38,
    titleENG: "All hospitals in arbil ",
    titleAR: "جميع مستشفيات اربيل        ",
  },
  {
    id: 39,
    titleENG: "Bazyan road and Drainage project",
    titleAR: "مشروع طريق وصرف بازيان        ",
  },
  {
    id: 40,
    titleENG:
      "cleaning and transforming Rubbish  western of sulaimanyah 2016- 2020",
    titleAR: "تنظيف وتحويل النفايات غربي السليمانية 2016-2020        ",
  },
  {
    id: 41,
    titleENG:
      "cleaning and transforming Rubbish  weastern of sulaimanyah 2021- 2023 continuous",
    titleAR: "تنظيف وتحويل نفايات غرب السليمانية 2021 - 2023 مستمر        ",
  },
  {
    id: 42,
    titleENG:
      "cleaning and transforming Rubbish  eastern of sulaimanyah 2021- 2023 continuous",
    titleAR: "تنظيف وتحويل النفايات شرقي السليمانية 2021 - 2023 مستمر        ",
  },
  {
    id: 43,
    titleENG: "Liquid fuels For transport With Ecossem compano 2021 continuous",
    titleAR: "وقود سائل للنقل مع Ecossem compano 2021 مستمر",
  },
]
  
