/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useRef } from "react";
import { LanguageContext } from "../../context/LanguageContext";
import img1 from "../../assets/images/home-bg.png";
import img2 from "../../assets/images/ourProjects/2.png";
import img3 from "../../assets/images/ourProjects/3.png";
import img4 from "../../assets/images/team_workers_working.png";
import img5 from "../../assets/images/team_workers_working_washing.png";
import img6 from "../../assets/images/ourProjects/5.png";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
const Hero = () => {
  useEffect(() => {
    Aos.init();
  }, []);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const yOffset = -100; // Adjust this value to set the margin
      const y =
        section.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  const { texts } = useContext(LanguageContext);

  const imageRef = useRef(null);
  const currentIndexRef = useRef(0);

  const images = [img1, img2, img3, img4, img5, img6];

  useEffect(() => {
    const interval = setInterval(() => {
      nextImage();
    }, 2500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const nextImage = () => {
    currentIndexRef.current = (currentIndexRef.current + 1) % images.length;
    imageRef.current.src = images[currentIndexRef.current];
  };
  return (
    <div id="hero">
      <div className="relative">
        <img
          src={images[0]}
          ref={imageRef}
          alt="slideshow"
          className=" w-full  h-[100vh] object-cover brightness-[.55] "
        />

        <div className="absolute top-0 left-0 right-0 bottom-0 flex flex-col justify-center items-center ">
          <h1
            className="text-xl text-center mx-auto md:text-2xl lg:text-4xl text-secondary mt-40"
            data-aos="fade-right"
          >
            {texts.hero_title}
          </h1>
          <p
            className="text-md w-[350px]  md:text-lg md:w-[400px] lg:text-xl text-secondary mt-8 lg:w-[600px] text-center tracking-wide"
            data-aos="fade-left"
          >
            {texts.hero_subtitle}
          </p>

          <Link
            className="mt-14  bg-primary w-56 h-12 flex justify-center items-center text-secondary text-lg rounded-md hover:bg-third  duration-200 transition-all"
            onClick={() => scrollToSection("contact")}
            data-aos="fade-up"
          >
            {texts.contact_us}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Hero;
