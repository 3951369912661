import { BrowserRouter } from "react-router-dom";
import "./assets/css/App.css";
import Navbar from "./layout/navbar";

import Route from "./routes/PageRoutes";
import { useContext } from "react";
import { LanguageContext } from "./context/LanguageContext";
import Footer from "./layout/footer";
function App() {
  const { language } = useContext(LanguageContext);
  return (
    <div
      className={`${
        language != "en" ? "font-arkufi" : "font-Poppins"
      }  overflow-x-hidden `}
      dir={`${language == "en" ? "ltr" : "rtl"}`}
    >
      <BrowserRouter>
        <Navbar />
        <Route />
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
