import React, { useContext, useState, useEffect } from "react";
import { LanguageContext } from "../context/LanguageContext";

import Aos from "aos";
import "aos/dist/aos.css";
const ServicesIcon = ({ icon, title, subtitle }) => {
  const { texts, language } = useContext(LanguageContext);
  const [finalTitle, setFinalTitle] = useState(title);

  useEffect(() => {
    if (title === texts.cleaning) {
      setFinalTitle(texts.cleaning);
    } else if (title === texts.waste_disposal) {
      setFinalTitle(texts.waste_disposal);
    } else if (title === texts.washing) {
      setFinalTitle(texts.washing);
    }
  }, [texts]);
  return (
    <div
      className={` text-center items-center flex justify-center flex-col lg:mx-32  ${
        language != "en" ? "font-arkufi" : "font-roboto"
      }`}
    >
      <h1
        className={`my-10 text-xl font-bold text-five  md:text-2xl lg:mt-5`}
        data-aos="fade-right"
      >
        {finalTitle}
      </h1>
      <img
        src={icon}
        alt="icon"    
        className={`${
          finalTitle === texts.waste_disposal
            ? "md:w-[126px] md:h-[126]"
            : "md:w-[100px] md:h-[100px]"
        }`}
        data-aos="fade-left"
      />
      <p
        className=" text-third flex  text-md md:text-xl mt-5 md:my-3  lg:my-10 lg:w-80 lg:h-[100px]"
        data-aos="fade-right"
      >
        {subtitle}
      </p>
    </div>
  );
};

export default ServicesIcon;
