import { useContext, useState, useEffect } from "react";
import { LanguageContext } from "../../context/LanguageContext";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "aos/dist/aos.css";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Video from "yet-another-react-lightbox/plugins/video";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import project1 from "../../assets/images/ourProjects/new-project1.jpg";
import project2 from "../../assets/images/ourProjects/new-project2.jpg";
import project3 from "../../assets/images/ourProjects/new-project3.jpg";
import project4 from "../../assets/images/ourProjects/new-project4.jpg";
import project5 from "../../assets/images/ourProjects/new-project5.jpg";
import project6 from "../../assets/images/ourProjects/new-project6.jpg";
import project8 from "../../assets/images/ourProjects/new-project8.jpg";
import project9 from "../../assets/images/ourProjects/new-project9.jpg";
import project10 from "../../assets/images/ourProjects/new-project10.jpg";
import project11 from "../../assets/images/ourProjects/new-project11.jpg";
import project12 from "../../assets/images/ourProjects/new-project12.jpg";
import project13 from "../../assets/images/ourProjects/new-project13.jpg";
const ProjectSection = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoPlaySpeed: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const projectImages = [
    {
      id: 1,
      src: project1,
    },
    {
      id: 2,
      src: project2,
    },
    {
      id: 3,
      src: project3,
    },
    {
      id: 4,
      src: project4,
    },
    {
      id: 5,
      src: project5,
    },
    {
      id: 6,
      src: project6,
    },
    {
      id: 7,
      src: project8,
    },
    {
      id: 8,
      src: project9,
    },
    {
      id: 9,
      src: project10,
    },
    {
      id: 10,
      src: project11,
    },
    {
      id: 11,
      src: project12,
    },
    {
      id: 12,
      src: project13,
    },
  ];
  const { texts } = useContext(LanguageContext);
  const [isOpen, setIsopen] = useState(false);
  const [index, setIndex] = useState(-1);

  return (
    <div className="flex justify-center items-center flex-col" id="projects">
      <h1
        className="flex justify-center items-center text-2xl  md:text-3xl font-bold mt-10"
        data-aos="zoom-out"
      >
        <span className="border-b-2 border-primary p-3">{texts.projects}</span>
      </h1>
      <div className="flex justify-center items-center" data-aos="zoom-out">
        <p className="text-third text-sm md:text-xl mt-8 mb-5 flex  text-center">
          {texts.projects_text}
        </p>
      </div>

      <Slider
        {...settings}
        className=" md:mx-48 my-14 gap-10 md:gap-32 w-[70%] md:w-[70%] lg:w-[50%]"
      >
        {projectImages.map((item, index) => (
          <div className="card-top px-3 flex justify-center items-center">
            <img
              src={item.src}
              alt={item.id}
              onClick={() => {
                setIndex(index);
                setIsopen(true);
              }}
              className=" object-cover cursor-pointer"
            />
          </div>
        ))}
      </Slider>
      <Lightbox
        index={index}
        open={isOpen}
        close={() => setIsopen(false)}
        slides={projectImages}
        plugins={[Fullscreen, Slideshow, Thumbnails, Video, Zoom]}
      />
      <div className="flex justify-center items-center" data-aos="zoom-in">
        <a href="/projects" className="text-2xl text-third">
          {texts.show_all}
        </a>
      </div>
    </div>
  );
};

export default ProjectSection;
