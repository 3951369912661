import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Team from "../assets/images/team_workers_working.png";
import { LanguageContext } from "../context/LanguageContext";
const About = () => {
  const { texts, language } = useContext(LanguageContext);
  return (
    <div className="">
      <div className="relative flex md:justify-center md:items-center mt-24 ">
        <img
          src={Team}
          alt="team"
          className="h-[500px] lg:h-full object-cover lg:w-[63%] brightness-75 md:rounded-xl"
        />
        <div className="flex flex-col items-center justify-center text-center absolute mt-[60%] md:mt-[30%] lg:mt-[20%] md:w-[80%] lg:w-[50%] gap-10">
          <h1 className="text-2xl md:text-4xl text-white">{texts.about}</h1>
          <p className="text-md mx-1 md:text-xl text-white">
            {texts.about_text}
          </p>
        </div>

        <div className={`flex  text-white  absolute  top-0 left-0 w-full   `}>
          <div
            className={`${
              language == "en"
                ? "flex justify-start ml-3 my-3 md:ml-10 lg:ml-[20%] "
                : "flex justify-end mr-3 my-3 md:mr-10 lg:mr-[20%]"
            }`}
          >
            <a href="/" className="">
              {texts.home}
            </a>
            &nbsp; {">"} &nbsp; <a className="">{texts.about}</a>
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-center items-center text-center mt-16 mx-2  lg:mx-auto lg:w-[60%]">
        <p className="text-xl text-third">{texts.about_sec_subtitle}</p>
        <h1 className="text-black text-4xl font-bold my-16">{texts.goal}</h1>
        <p className="text-xl text-third mb-16">{texts.goal_subtitle}</p>
      </div>
    </div>
  );
};

export default About;
